/*
%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
       Detect IDX Page for WP Menu
%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
*/

jQuery(document).ready(function() {
   var curPageUrl, curPageUrlName, $;
   $ = jQuery;
   curPageUrl = window.location.href;
   $('.header-nav > .menu li a').each(function(){
      if( $(this).attr('href') === curPageUrl ){
         $(this).parent().addClass('current-menu-item');
      }
   });
});