/*
%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
               Search Box
%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
*/

jQuery(document).ready(function() {

    var $ = jQuery.noConflict();

    $('#searchsubmit').on('mouseover', function() {
        if ($('.search-input').css("opacity") !== 1) {
            $('.search-input').animate({
                opacity: 1
            }, 1000, 'easeInOutQuint', function() {
                $('.search-input').css({
                    'z-index': '7',
                    'pointer-events': 'auto'
                });
                $('#s').focus();
            });
        }
    }).on('click', function(e) {
        if ($('.search-input').css("opacity") == "1" && $('.search-input #s').val() !== "search" && $('.search-input #s').val() !== "") {
            $("#searchform").submit();
        }
    });

    $('.resetform').click(function() {
        $('#s').val("").focus();
        $('.search-input').animate({
            opacity: 0
        }, 1300, 'easeInOutQuint', function() {
            $(this).css({
                'z-index': '1',
                'pointer-events': 'none'
            });
        });
    });

    $('#s').click(function() {
        if ($(this).val() === 'search') {
            $(this).val("");
        }
    });

});
