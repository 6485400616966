/*
%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
              Mobile Menu
%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
*/

jQuery(document).on('ready', function() {

    var $ = jQuery.noConflict();

    var $curMobileScroll;

/*
        $hk_Menu = $('#wrapper > header > .header_menu');

        $hk_Menu.eq(0).toggleClass('menu_container').toggle();

        if ( $hk_Menu.is(":visible") ) {
            $curMobileScroll = $(window).scrollTop();
             $hk_Menu.toggle();
            $('.mobileMenuWrap').toggle();
        } else {
            $('#wrap').toggle();
            $(window).scrollTop($curMobileScroll);
            $('.mobileMenuWrap').toggle();
        }
*/

/*
    $hk_Menu = $('#wrapper > header > .header_menu');

    function close_menu() {
        $hk_Menu.eq(0).removeClass('menu_container').hide();
    }

    function open_menu() {
        $hk_Menu.eq(0).addClass('menu_container').show();
    }

    $('.menu_toggle > a').click(function() {
        if ( $hk_Menu.is(":visible") ) {
            close_menu();
        } else {
            open_menu();
        }
    });

    $(window).on('resize', function(){
        if( window.outerWidth > 1024 ) {
            open_menu();
        } else {
            close_menu();
        }
    } );
*/
});