/*
%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
               ReCaptcha
%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
*/

jQuery(window).on('load', function() {

    var $ = jQuery.noConflict();
    var ccrForms = [],
        loadingImg = window.location.origin + '/wp-content/themes/campbellwellman/images/loading.gif',
        $theCapt;

    function ccrcaptcha() {

        $('[class*="general-form"]').each(function(i) {

            // find the placeholder for the captcha
            $theCapt = $(this).children().find('.gcap')[0]; //the [0] for theCapt was necessary for this to work.

            // add a new form object to the forms array, and render the captcha
            ccrForms[i] = grecaptcha.render($theCapt, {
                'sitekey': '6LfrZhkTAAAAAJffWJQXLKrYNItZoCVvM3i2V4fh',
                'callback': verifyCallback,
                'theme': 'light'
            });

            // show loader
            $($theCapt).css('background', 'url('+loadingImg+') no-repeat scroll center center transparent');

            // add a honeypot as well
            $($theCapt).parent().append('<input type="text" name="form_message"></input>');

        });

    }

    ccrcaptcha();

    var verifyCallback = function(response) {
        //console.log("captcha response is: " + response);
    };

    $('.reset-recaptcha').click(function() {
        $(this).prev().empty();
        grecaptcha.reset();
    });

}); // end on doc ready